.header-container {
  background-color: #101376;
  
}

.header {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.header__tel {
  display: flex;
  align-items: center;
}

.header__tel p {
  font-size: 10px; 
  margin-left: 5px; 
}

.header__tel a {
  color: rgb(245, 243, 239);
  text-decoration: none;
  transition: color 0.3s;
  font-size: 18px;
}

.header__tel a:hover {
  color: darkorange;
}


.header p {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px; 
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav li {
  display: inline-block;
  margin-right: 10px;
}

nav li a {
  padding: 8px 16px;
  background-color: #555;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

nav li a:hover {
  background-color: #444;
}

nav li a.active {
  background-color: #ec9005; 
  color: #333; 
}

.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-icon {
  color: #fff;
  font-size: 24px;
}


.nav-menu {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: translateX(0); 
}

.nav-menu.open {
  transform: translateX(0); 
}

.nav-menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin-right: 10px;
}

@media (max-width: 650px) {
  .burger-menu {
    display: block;
    position: absolute;
    top: 45px;
    right: 40px;
  }


  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    top: 65px;
    left: 0;
    background-color: #101376;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 4px; 
    transform: translateX(-100%); 
  }

  .nav-menu.open {
    transform: translateX(0);
    z-index: 1;
    align-items: center;
    padding: 20px 0;
}

  .nav-menu ul {
    flex-direction: column; 
    align-items: center; 
    width: 100px;
  }

  .nav-menu li {
    margin-right: 0; 
    margin-bottom: 10px; 
    padding:  10px;
  }

  nav li a {
    display: inline-block;
    width: 75px;
    text-align: center;
  }


  .nav-menu li:last-child {
    margin-bottom: 0; 
  }
}
