/* Default styles for larger screens */
.Services {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordionItem {
  width: 100%; /* Change to full width for better responsiveness */
  margin: 10px 0;
}

.Services h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f1f1f1;
  color: #333;
  padding: 10px;
  border: none;
  text-align: left;
  outline: none;
  transition: background-color 0.2s;
}

.Services h2 span {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Services h2:hover {
  background-color: #ddd;
}

.accordionItem p {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.accordionItem.open p {
  max-height: 800px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .Services {
    padding: 0 15px;
  }

  .Services h2 {
    font-size: 18px; /* Adjust font size as needed for readability */
  }
}
