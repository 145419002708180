.Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Info__title {
    text-align: center;
}

.Info__box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Info__tel {
    display: flex;
    align-items: center;
}

.Info__tel p {
    padding: 0 10px;
}

.Info__box p {
    padding: 0 10px;
}

.Info__adres {
    display: flex;
    align-items: center;
}

.Info__adres p {
    padding: 0 10px;
}

.Info__adres-box{
    display: flex;
    align-items: center;
}

.Info__name {
    padding-right: 10px;
}

.Info__tel p a {
    color: rgb(0, 0, 0); 
    text-decoration: none;
  }

@media (max-width: 768px) {
    .Info {
      padding: 20px; 
      align-items: center;
    }
  
    .Info__box {
      flex-direction: column; 
      align-items: center; 
    }

    .Info__adres {
        flex-direction: column;
        align-items: center;
        width: 225px;
    }

    
  }