.company-assortment {
    text-align: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  }
  
  .company-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .company-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: #333;
  }
  
  .company-image {
    width: 250px;
    height: 250px;
    object-fit: contain;
  }
  
  .company-name {
    margin-top: 20px;
    font-weight: bold;
    width: 250px;
    font-size: 20px;
  }
  
  .back-icon {
  vertical-align: middle;
  margin-right: 5px;
}

.company-assortment a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  margin-bottom: 20px;
}
