.welkom-container {
  background-color: #edbf19;
  padding: 20px; 
}

.welkom {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px; 
}

.welkom img {
  max-width: 200px; 
  margin-right: 20px; 
}

.welkom h1 {
  font-size: 28px; 
}


@media (max-width: 760px) {
  .welkom-container {
    display: none;
  }
}