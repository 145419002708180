.Home {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
  .Home h1 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center; 
  }
  
  .Home p {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center; 
  }
  
  .Home__title {
    font-size: 24px;
    margin-top: 30px;
    text-align: center; 
  }
  
  .Home__company {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .Home__company img {
    width: 200px; 
    object-fit: scale-down;
    height: 200px; 
    margin: 10px; 
  }
  
.Home__link {
 display: block; 
  margin: 25px auto; 
  padding: 10px 20px;
  font-size: 20px;
  background-color: #e97e04;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
   text-decoration: none; 
}

.Home__link a {
  text-decoration: none;
  color: aliceblue;
}

.Home__link:hover {
  background-color: #ff9900;
}

@media (max-width: 768px) {
  .Home {
    padding: 0 15px;
  }
  
  .Home h1 {
    font-size: 28px;
  }
  
  .Home p {
    font-size: 16px;
  }
  
  .Home__title {
    font-size: 20px;
  }
  
  .Home__company img {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 480px) {
  .Home h1 {
    font-size: 24px;
  }
  
  .Home p {
    font-size: 14px;
  }
  
  .Home__title {
    font-size: 18px;
    margin-top: 20px;
  }
  
  .Home__company img {
    width: 120px;
    height: 120px;
    margin: 10px;
  }
}
